import React, { useState } from 'react'
import contractorLogo from '/app/assets/images/pages/home/contractor_report/icons/Contractor-report.svg'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ContractorReportFileds } from './form/dataField'
import ContractorReportFormInfo from './form/formInfo'
import ContractorReportFormSection from './form/formSection'
import ContractorReportFormField from './form/formField'
import ContractorReportFormSuccess from './form/formSuccess'
import { createContractorReportApi } from './api'

export default function FormContractorReport({ buttonText, buttonClasses, currentUser }) {
  const [step, setStep] = useState(1)
  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  const processForm = (formData) => {
    createContractorReportApi({
      contractor_report: formData,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
    reset()
    setStep(4)
  }

  const next = async (fieldName) => {
    try {
      const hasNoError = await trigger(fieldName)
      if (!hasNoError) return
      setStep((prev) => prev + 1)
    } catch (error) {}
  }

  const prev = () => {
    setStep((prev) => prev - 1)
  }

  const renderComponent = () => {
    switch (step) {
      case 1:
        return <ContractorReportFormInfo handleClick={() => setStep(2)} />
      case 2:
        return (
          <ContractorReportFormSection
            onBack={prev}
            showBackButton
            showNextButton
            title={ContractorReportFileds.contractor.title}
            onNext={() => next(ContractorReportFileds.contractor.name)}
          >
            {ContractorReportFileds.contractor.fields.map((field) => {
              return (
                <ContractorReportFormField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  register={register}
                  validation={field.validation}
                  errorMessage={field?.errorMessage?.({ errors })}
                />
              )
            })}
          </ContractorReportFormSection>
        )
      case 3:
        return (
          <ContractorReportFormSection
            title={ContractorReportFileds.requester.title}
            onSubmit
            onBack={prev}
            showBackButton
          >
            {ContractorReportFileds.requester.fields.map((field) => {
              const validation = typeof field.validation === 'function' ? field.validation({ watch }) : field.validation
              return (
                <ContractorReportFormField
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  register={register}
                  validation={validation}
                  errorMessage={field?.errorMessage?.({ errors })}
                />
              )
            })}
          </ContractorReportFormSection>
        )
      case 4:
        return <ContractorReportFormSuccess handleClick={() => setStep(1)} />
      default:
        break
    }
  }

  return (
    <div className="form-contractor-report">
      <img src={contractorLogo} />
      <form onSubmit={handleSubmit(processForm)}>{renderComponent()}</form>
    </div>
  )
}
